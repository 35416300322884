body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.8);*/
  -webkit-border-radius: 10px;
  background-color: #000;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #666;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.floatright {
  float: right;
}
.ssproducts {
  background: #191a1e;
  padding: 10px;
  border-radius: 5px;
}
.itemname {
  margin-bottom: 12px;
  font-weight: bold;
}
.quantitybox {
  background: #101114;
  margin-top: 12px;
  border-radius: 5px;
}
.MuiStepIcon-root {
  color: #000 !important;
}
.MuiStepIcon-root.Mui-active {
  color: #63b575 !important;
}
.refdetailtext {
  display: flex;
  text-align: left;
  & span {
    font-size: 13px;
    margin-right: 20px;
    text-align: left !important;
    &:nth-of-type(1) {
      flex: 2;
    }
    &:nth-of-type(2) {
      flex: 1;
    }
    &:nth-of-type(3) {
      flex: 1;
    }
  }
}
span.totalref {
  float: right;
  font-size: 15px;
  font-weight: bold;
  background: #376a39;
  padding: 5px 15px;
  border-radius: 3px;
}
.editordante {
  padding: 10px;
  background: #1c2125;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #595c61;
  height: 100%;
  padding: 0px !important;

  &.node {
    height: 500px;
    & .RichEditor-root {
      padding-bottom: 0px !important;
    }
    & .rdw-editor-main {
      max-height: 250px !important;
      height: 160px !important;
    }
  }
}
@media screen and(min-width:1000px) {
  .editordante {
    &.node {
      & .rdw-editor-main {
        max-height: 250px !important;
        height: 250px !important;
      }
    }
  }
}
@media screen and(min-width:1300px) {
  .editordante {
    &.node {
      & .rdw-editor-main {
        max-height: 350px !important;
        height: 350px !important;
      }
    }
  }
}
.nodes_box {
  background: #0c0c0c;
  padding: 20px;
  margin-top: 10px;
  border-radius: 5px;
}
.linkcolorwhite {
  color: #fff;
}
.customernote_author b {
  display: block;
  color: #4caf50;
}
.notesbtn {
  background: #4caf4f;
  float: right;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  padding: 0px 10px;
  border-radius: 3px;
}
.shippingbtn {
  background: #24a3bb;
  float: right;
  margin-left: 10px;
  color: #fff;
  font-size: 14px;
  padding: 0px 10px;
  border-radius: 3px;
}
.shippingdt_badges {
  font-weight: bold;
  color: #fff;
}
span.shippingamount {
  background: #2196f38a;
  padding: 4px 10px;
  font-size: 12px;
  margin-left: 6px;
  border-radius: 3px;
}
span.shippingweight {
  background: #673ab7;
  padding: 4px 10px;
  font-size: 12px;
  margin-left: 6px;
  border-radius: 3px;
}
.shippingnotes {
  margin-top: 10px;
}
.awaitingdate {
  background: none !important;
  border: 1px solid #ffeb3b !important;
  color: #ffeb3b !important;
  text-align: center;
}
.cancelleddate {
  background: none !important;
  border: 1px solid #d84d4d !important;
  color: #d84d4d !important;
  text-align: center;
}
.shippingdate {
  border: 1px solid #4caf50;
  color: #4caf50;
  padding: 10px;
  width: 130px;
  display: grid;
  margin-left: 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13px;
  float: right;
  text-align: center;
}
.shippingdt {
  max-width: 630px;
  background: #1a1c20;
  padding: 10px 15px;
  border-radius: 5px;
}
.textfield-link {
  text-decoration: none;
  & input {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
}
.menu-link {
  & input {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
}
.analyticsSpan span {
  font-size: 20px;
  display: flow-root;
  margin-top: 10px;
  color: #fff;
}
.affiliatetext span {
  display: inline-block;
  margin-right: 10px;
}
.premiumbadge {
  padding: 2px 10px;
  background: #ffc107;
  color: #000;
  border-radius: 3px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  width: 100%;
}
.shippingbadge {
  padding: 2px 10px;
  background: #607d8b;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  width: 100%;
}
.imagesecret {
  width: 25%;
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  border: 2px solid #212529;
}
.recent-activity {
  font-size: 10px;
  background: #3f51b5;
  padding: 5px 10px;
  border-radius: 3px;
  opacity: 0.5;
}
.soonbox {
  position: relative;
}
.soonbox span {
  position: absolute;
  z-index: 1;
  background: #e91e63;
  font-size: 14px;
  padding: 2px 65px;
  -webkit-transform: rotate(307deg);
  -moz-transform: rotate(307deg);
  -o-transform: rotate(307deg);
  -ms-transform: rotate(307deg);
  transform: rotate(307deg);
  left: -90px;
}
.imagesecret img {
  width: 100%;
  height: 100px;
}
.skus_box {
  margin-top: 10px;
  background: #000;
  display: grid;
  position: relative;
  width: 100%;
  float: left;
  padding: 13px;
  border-radius: 5px;
}
.skus_box h3 {
  margin: 0;
}
.whitelink a {
  color: #fff;
  font-weight: bold;
}
.titlebox {
  font-size: 19px;
  margin-bottom: 14px;
}
.infobar {
  background: #03a9f4;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.deletephotobtn {
  position: absolute;
  background: #1c2125;
  right: 0;
}
.qrbox img {
  float: left;
  width: 50%;
}
.MuiIconButton-edgeStart {
  margin-left: -6px !important;
}
.paddingdate {
  padding: 20px;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
}
.sticky {
  position: fixed;
  width: 250px;
}
.yellowcolor {
  color: #ffc107 !important;
}
.max1200 {
  max-width: 1200px;
}
.borderblue {
  border-bottom: 2px solid #2196f3;
}
.borderred {
  border-bottom: 2px solid #ff5722;
}
.bordergreen {
  border-bottom: 2px solid #4caf4f;
}
.borderyellow {
  border-bottom: 2px solid #ffc107;
}
.summarybox b {
  float: right;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
}
.nopadding {
  padding: 0;
}
.nopadding .MuiListItem-root {
  padding-left: 0 !important;
  padding: 0;
}
.subs span {
  margin-right: 10px;
}
.subscriptions .active {
  border-radius: 3px;
  background: #4caf50;
  padding: 2px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
}
.subscriptions .trialing {
  border-radius: 3px;
  background: #ffca02;
  padding: 3px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #252525;
}

.subscriptions .canceled {
  border-radius: 3px;
  background: #000;
  padding: 3px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.subs b:first-child {
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
}
.subs b {
  font-size: 14px;
  font-weight: normal;
  margin-right: 10px;
}
.badgemenu b {
  background: #4caf50;
  padding: 2px;
  border-radius: 3px;

  height: 20px;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}
.green {
  color: #63b574;
}
.secretshopbox {
  padding: 6px;
}
.margintop15 {
  margin-top: 15px;
}
.marginright10 {
  margin-right: 10px !important;
}
.featuredImgBlock {
  position: relative;
  &.thumbnail {
    border: 1px solid;
    border-radius: 5px;
    padding: 16.5px 14px;
    border-color: rgb(118, 118, 118);
  }
}
.fullWidthImage img {
  width: 100%;
}
// mui themesettings
.MuiToolbar-gutters {
  padding-left: 10px !important;
}
.MuiPaper-root {
  background-image: none !important;
}
.MuiDrawer-paperAnchorDockedLeft {
  border-right: none !important;
}
.MuiTableCell-root {
  border-bottom: 0 !important;
}
.MuiTableRow-root:hover {
  background: #141619;
}
.MuiTableRow-root {
  border-bottom: 5px solid #292c33 !important;
}
.summary {
  padding: 30px;
  background: #000;
  border-radius: 5px;
  margin-top: 16px;
}
.stocknumber {
  float: right;
  background: #63b475;
  padding: 4px;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.stocknumb {
  float: right;
  background: #000;
  padding: 4px;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}
.stocklist {
  width: 100% !important;
  display: block !important;
}
.summary input {
  color: #fff;
}
.numbers button {
  width: 31%;
  color: #fff !important;
  border: 1px solid #00ab6b;
  margin: 4px;
  font-size: 42px;
}
.badgemenu .MuiListItemIcon-root {
  min-width: 27px;
  color: #686d73;
}
.usersradioselect {
  background: #141619;
  width: 150px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}
.numbers button.Mui-disabled {
  opacity: 0.5;
}
.passValid input {
  color: #fff;
  font-size: 50px;
  text-align: center;
}
th,
td {
  position: relative !important;
}
.greentextb b {
  color: #61b474;
}
.floatrightcircular {
  float: right;
  margin-left: 10px;
}
.dryingbutton.Mui-disabled {
  opacity: 0.4;
}
.packingbutton.Mui-disabled {
  opacity: 0.4;
}

.splitcolor {
  background: #d84d4d;
  padding: 3px 6px;
  margin-right: 4px;
}
.productname {
  padding: 4px 7px;
  border-left: 2px solid;
}
.yellowsplitcolor {
  background: rgba(255, 255, 255, 0.3);
  padding: 3px 6px;
  margin-right: 4px;
  color: #000;
}
.disabled th::after {
  position: absolute;
  content: "";
  background: rgba(42, 45, 51, 0.82);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.disabled td::after {
  position: absolute;
  content: "";
  background: rgba(42, 45, 51, 0.82);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.authorlocked {
  position: absolute;
  z-index: 100;
  top: 14px;
  background: #00ab6b85;
  padding: 9px;
  border-radius: 5px;
}
// mui themesettings end

ul {
  padding: 0;
}
.loginbox {
  margin-top: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
  }
}
.toolbar {
  border-top: 2px solid #63b474;
}
.themesettings {
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.loadingbutton {
  margin-left: 10px;
}
.sidelogo {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  & img {
    width: 43px;
    margin-left: 0;
    margin-right: 10px;
    &:nth-of-type(2) {
      width: 80px;
    }
  }
  & span {
    font-size: 1.1em;
    font-weight: 200;
  }
}

.userinfoside {
  padding: 10px;
  margin: 5px;
  border: none !important;
}
.pagetop {
  height: 70px;
  padding-top: 20px;
  padding-bottom: 60px;

  .right {
    float: right;
  }
  .left {
    float: left;
    padding: 10px;
    padding-left: 10px;
  }
  .left a {
    color: #fff;
  }
}

.published {
  background: #62b575;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
  margin-top: 2px;
}
.archived {
  background: rgba(255, 255, 255, 0.08);
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 11px;
  margin-top: 2px;
}

.padding10 {
  padding: 10px;
}
.padding20 {
  padding: 20px;
}
.customername {
  font-size: 16px;
  font-weight: bold;
}
.width100 {
  width: 100%;
}
.inlineTooltip-button {
  background-color: #63b475 !important;
}
.inlineTooltip-button path {
  fill: #fff;
}
.public-DraftEditorPlaceholder-root {
  color: #ffffff;
  position: absolute;
  z-index: 0;
  background-color: #1c2025 !important;
}
.mr10 {
  margin-top: 26px !important;
}
.addpacking {
  background: #121315;
  padding: 15px;
  border-radius: 5px;
}
.activebins {
  color: #62b575;
  font-weight: normal;
}
.binlist {
  padding: 5px 4px;
  border: 1px solid #64656a;
  padding: 7px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.graf--h4 {
  color: rgba(255, 255, 255, 1) !important;
}
.addblogtitle {
  font-size: 32px !important;
}
.greenborder.Mui-disabled {
  border-color: #62b575 !important;
  color: #62b575 !important;
}
.orangeborder.MuiButton-outlined {
  border-color: #f1834f !important;
  color: #f1834f !important;
}
.redborder.MuiButton-outlined {
  border-color: #d84d4d !important;
  color: #d84d4d !important;
}
.yellowborder.MuiButton-outlined {
  border-color: #ffc221 !important;
  color: #ffc221 !important;
}
.yellowborder.MuiButton-outlined:hover {
  background: #ffc221;
  color: #fff !important;
}
.blueborder.MuiButton-outlined {
  border-color: #24a3bb !important;
  color: #24a3bb !important;
}
.lightpinkborder.MuiButton-outlined {
  border-color: #ecb1f3 !important;
  color: #ecb1f3 !important;
}
.fdborder.MuiButton-outlined {
  border-color: #e25065 !important;
  color: #e25065 !important;
}
.fdborder.MuiButton-outlined:hover {
  background: #e25065;
  color: #fff !important;
}

.opacity1 {
  opacity: 0.3;
}

.warningbox {
  background: #f44336;
  padding: 5px;
  width: 100%;
  text-align: center;
  font-weight: 800;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  margin: 8px;
  margin-top: 0;
}

.searchresult span {
  background: #000;
  margin-left: 10px;
  font-size: 12px;
  padding: 5px;
  border-radius: 4px;
}

.feedreal-main {
  margin-top: 15px;
}

.button-holder {
  color: #21242a00 !important;
}
.member-data {
  padding: 10px;
  & div {
    margin-bottom: 10px;
  }
}

.dog-name {
  & div {
    margin-bottom: 10px;
    & input {
      padding: 10px;
    }
  }
}

// draftjs style edit
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.t-b {
  border: 1px solid red;
}
.full {
  width: 100vw;
  height: 100vh;
  background-color: #282c34;
}
.input {
  margin: 10px 0px;
}
.RichEditor-root {
  background: #1c2125;
  font-family: "Georgia", serif;
  font-size: 14px;
  padding: 15px;
  padding-bottom: 80px;
  width: 100% !important;
  img {
    max-width: 100% !important;
    height: auto !important;
  }
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.rdw-option-wrapper {
  color: #252525;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: none !important ;
  display: flex;
  justify-content: flex-start;
  background: transparent !important ;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  user-select: none;
}
.RichEditor-editor {
  border: none !important;
}
.btn-text-left {
  justify-content: flex-start !important;
}
.btn-text-margin-left {
  width: 100% !important;
  padding-left: 10% !important;
}
.every-child-margin > * {
  margin-bottom: 10px !important;
}

.selected-tutorial-type {
  border: 1px solid gray !important;
  border-radius: 5px !important;
}

.rightbox {
  position: relative;
  & #article {
    margin-left: 30px;
  }
  & .iconright {
    position: absolute;
    position: absolute;
    left: -30px;
    width: 50px;
    text-align: right;
    & img {
      max-width: 100%;
    }
  }
}

.card-preview-container {
  border: 1px solid rgba(255, 255, 255, 0.19);
  border-radius: 5px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  &::after {
    content: "PREVIEW";
    font-weight: 600;
    position: absolute;
    text-align: center;
    width: 140px;
    background-color: #f44336;
    top: 19px;
    right: -39px;
    transform: rotateZ(45deg);
  }
}
.rdw-dropdownoption-active {
  background-color: gainsboro;
  color: black;
}
.rdw-dropdownoption-default {
  color: black;
}
.rdw-dropdown-selectedtext {
  color: black;
}
.rdw-link-modal {
  background: #282b32 !important;
}
.rdw-link-modal-target-option {
  color: white !important;
  & span {
    color: white !important;
  }
}
.rdw-embedded-modal {
  background: #282b32 !important;
}
.rdw-embedded-modal-target-option {
  color: white !important;
  & span {
    color: white !important;
  }
}
.rdw-image-modal {
  background: #282b32 !important;
}
.rdw-image-modal-target-option {
  color: white !important;
  & span {
    color: white !important;
  }
}
.update-btn {
  &:hover {
    background-color: #0c140e !important;
  }
}

.red-line {
  box-shadow: inset 3px 0px 0px 0px #e25166;
  -webkit-box-shadow: inset 3px 0px 0px 0px #e25166;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  & .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
}

.rdw-image-imagewrapper img {
  width: 100% !important;
}
#article {
  margin-left: 30px;
  max-width: 800px;
  margin: 0 auto;
  & img {
    width: 100% !important;
  }
  & a {
    color: #00ab6b;
    text-decoration: none !important;
    font-weight: 500;
  }
}

.green-fill {
  fill: #00ab6b !important;
}
.red-fill {
  fill: #f44336 !important;
}

@media screen and (min-width: 768px) {
  #displayMobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #displayMobile {
    display: block;
  }
}

.button_div_flex {
  //Create div inside the mui button first.
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.publish_multiple {
  padding-top: inherit;
}

@media screen and (max-width: 767px) {
  .publish_multiple {
    padding-top: 20px;
  }
}

.update_product-header {
  position: sticky;
  top: 0px;
  right: 0px;
  background-color: black;
  padding: 10px 10px 10px 20px;
  border-bottom-left-radius: 10px;
  z-index: 10;
  margin-left: 30%;
}

@media screen and (max-width: 767px) {
  .update_product-header {
    position: sticky;
    top: 0px;
    right: 0px;
    background-color: black;
    padding: 10px 10px 10px 20px;
    border-bottom-left-radius: 0px;
    z-index: 10;
    margin-left: 0px;
    display: flex;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.btn-reward {
  width: 100%;
  min-height: 200px;
}

.contentPermission {
  width: 100%;
  padding: 8px !important;
  border: 1px solid #ffffff60 !important;
  border-radius: 4px !important;
  .Mui-checked {
    color: #63b475 !important;
  }
}
