
.price-tag-vertical-active {
    width: 40px;
    height: 68px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background-image: linear-gradient(30deg, transparent 40px, #66bb6a 0%),
      linear-gradient(-30deg, transparent 40px, #66bb6a 0%);
    right: 5px;
    position: absolute;
    line-height: 14px;
    padding-top: 5px;
    margin-top: -1px;
    z-index: 10;
  }
  .price-tag-vertical-sold-out {
    width: 40px;
    height: 68px;
    display: inline-block;
    color: #fff;
    text-align: center;
    background-image: linear-gradient(30deg, transparent 40px, #f44336 0%),
      linear-gradient(-30deg, transparent 40px, #f44336 0%);
    right: 5px;
    position: absolute;
    line-height: 14px;
    padding-top: 5px;
    margin-top: -1px;
    z-index: 10;
  }